import React, { useMemo } from "react";
import { Accordion, Col, Image, Row } from "react-bootstrap";
import Static from "../../assets/statics";

const args = {
  faculty: {},
  department: {},
};

function FacultyDepartmentDetails(props = { ...args }) {
  props = { ...args, ...props };

  const programs = useMemo(() => {
    return Static.Programs.undergraduate.programs.filter(
      (p) => p.department === props.department?.slug
    );
  }, [props.department]);

  function getParagraphs(string, separator = "/") {
    return string.split(separator).map((str, i) => (
      <span className="m-0 d-block" key={`paragraph_${i}`}>
        {str}
      </span>
    ));
  }

  return (
    <>
      <section className="border-bottom py-0 mb-3">
        <h4 className="fw-bold mb-0">Department of {props.department?.name}</h4>
        <p className="text-muted mb-2">Faculty of {props.faculty?.title}</p>
      </section>

      <p className="text-justify">{props.department?.description}</p>

      <section id="hod-details" className="py-0">
        <h5 className="fw-bold">Head of Department</h5>

        <Row className="mt-3">
          <Col md className="order-1">
            <p className="fs-5 fw-semibold mb-0">
              {props.department?.hod?.name}
            </p>
            <Row className="mb-3 gy-2">
              <Col sm>
                <small className="fw-semibold">Phone</small>
                {getParagraphs(props.department?.hod?.contact?.phone)}
              </Col>

              <Col sm>
                <small className="fw-semibold">Email</small>
                {getParagraphs(props.department?.hod?.contact?.email)}
              </Col>
            </Row>

            <p className="text-justify">
              <span className="fw-bold text-muted">Bio</span>
              <br />
              {props.department?.hod?.bio}
            </p>

            <section className="text-justify py-0">
              <span className="fw-bold text-muted">Qualifications</span>
              <br />
              <ul>
                {props.department?.hod?.qualifications?.map((qua, idx) => (
                  <li key={`qualification_${idx}`}>{qua}</li>
                ))}
              </ul>
            </section>
          </Col>

          <Col md={3} className="order-0">
            <Image
              src={`/images/hod/${props.department?.hod?.photo}`}
              alt={`photo_hod_${props.department?.slug}`}
              className="img-fluid img-thumbnail"
            />
          </Col>
        </Row>
      </section>

      <section id="programs" className="py-0">
        <div className="mb-3">
          <h5 className="fw-bold mb-0">Programs</h5>
          <small>
            <em>Select program to see requirements</em>
          </small>
        </div>

        <Accordion defaultActiveKey={0}>
          {programs.map((program, index) => (
            <Accordion.Item
              key={`program_${index}`}
              eventKey={index}
              className="rounded-0"
            >
              <Accordion.Header className="fw-semibold">
                {program.name}
              </Accordion.Header>

              <Accordion.Body>
                <div>
                  <strong>Program Duration</strong>
                  <p className="lead">{program?.duration}</p>
                </div>

                <div>
                  <strong>Campus</strong>
                  <p className="lead">{program?.campus}</p>
                </div>

                <div>
                  <strong>Entry Requirements</strong>
                  <ol type="i">
                    {program.requirements?.map &&
                      program?.requirements.map((req, idx) => (
                        <li key={`req_${program.department}_${idx}`}>{req}</li>
                      ))}
                  </ol>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </section>
    </>
  );
}

export default FacultyDepartmentDetails;
